import React from "react";
import "./styles.less";
import { Row } from "antd";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { richTextOptions } from "../../../utils/richTextOptions";

const PageBody = (props) => {
  return (
    <div className="page-body">
      <Row justify="center" align="middle">
        {documentToReactComponents(props.body.json, richTextOptions)}
      </Row>
    </div>
  );
};

export default PageBody;
