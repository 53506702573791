import React from "react";
import { Col } from "antd";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const DEFAULT_LANG = "en-US";

const Column = (fields) => {
  const sizes = {
    xs: fields.xs && fields.xs[DEFAULT_LANG],
    sm: fields.sm && fields.sm[DEFAULT_LANG],
    md: fields.md && fields.md[DEFAULT_LANG],
    lg: fields.lg && fields.lg[DEFAULT_LANG],
    xl: fields.xl && fields.xl[DEFAULT_LANG],
  };

  return (
    <Col {...sizes}>
      <div className="inner-col">
        {documentToReactComponents(
          fields.bodyRich[DEFAULT_LANG],
          richTextOptions
        )}
      </div>
    </Col>
  );
};

export const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      if (node.data.target.fields) {
        const { title, description, file } = node.data.target.fields;
        const mimeType = file["en-US"].contentType;
        const mimeGroup = mimeType.split("/")[0];

        switch (mimeGroup) {
          case "image":
            return (
              <img
                style={{ maxWidth: "100%" }}
                title={title ? title["en-US"] : null}
                alt={description ? description["en-US"] : null}
                src={file["en-US"].url}
              />
            );
          case "application":
            return (
              <a
                alt={description ? description["en-US"] : null}
                href={file["en-US"].url}
              >
                {title ? title["en-US"] : file["en-US"].details.fileName}
              </a>
            );
          default:
            return (
              <span style={{ backgroundColor: "red", color: "white" }}>
                {" "}
                {mimeType} embedded asset{" "}
              </span>
            );
        }
      }
    },

    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { fields, sys } = node.data.target;
      if (!sys.contentType) return null;
      switch (sys.contentType.sys.id) {
        case "column":
          return <Column {...fields} />;
        default:
          return null;
      }
    },
  },
};
