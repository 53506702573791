import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import config from "../utils/siteConfig";
import Layout from "../components/Layout/Layout";
import PageBody from "../components/Layout/PageBody";
import PageFooter from "../components/Layout/PageFooter";
import SEO from "../components/SEO";

const PageTemplate = ({ data, location, pageContext }) => {
  const { title, body } = data.contentfulPage;
  const { slug } = pageContext;
  const postNode = data.contentfulPage;

  return (
    <Layout location={location}>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} pageSEO />

      <div className="container">
        <div className="simple-title">
          <h1>{title}</h1>
        </div>

        <PageBody body={body} />
      </div>
      <PageFooter />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      metaDescription {
        internal {
          content
        }
      }
      body {
        json
      }
    }
  }
`;

export default PageTemplate;
